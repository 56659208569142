// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-install-jsx": () => import("./../../../src/pages/install.jsx" /* webpackChunkName: "component---src-pages-install-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-request-demo-jsx": () => import("./../../../src/pages/request-demo.jsx" /* webpackChunkName: "component---src-pages-request-demo-jsx" */),
  "component---src-pages-sell-through-calculator-jsx": () => import("./../../../src/pages/sell-through-calculator.jsx" /* webpackChunkName: "component---src-pages-sell-through-calculator-jsx" */),
  "component---src-pages-start-jsx": () => import("./../../../src/pages/start.jsx" /* webpackChunkName: "component---src-pages-start-jsx" */),
  "component---src-pages-tos-jsx": () => import("./../../../src/pages/tos.jsx" /* webpackChunkName: "component---src-pages-tos-jsx" */),
  "component---src-templates-blog-author-jsx": () => import("./../../../src/templates/blogAuthor.jsx" /* webpackChunkName: "component---src-templates-blog-author-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

